@font-face {
    font-family: "Apoc";
    src: url("assets/fonts/Apoc-Regular-Web.woff2") format("woff2"),
         url("assets/fonts/Apoc-Regular-Web.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
:root {
	color-scheme: light;
}
body {
	margin: 0;
	font-family: 'Apoc', serif;
	background-color: Canvas;
	color: CanvasText;
	line-height: 1.5;
}
.visually-hidden {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;

  white-space: nowrap;

  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}
.container {
	padding-left: 1em;
	padding-right: 1em;
}

.main {
	display: flex;
	flex-wrap: wrap;
	column-gap: 6em;
}
.main__title {
	font-family: system-ui, sans-serif;
	font-weight: 500;
	flex-basis: 100%;
	margin-bottom: 0;
	line-height: 1.2;
}
.main__contributors_heading {
	font-family: system-ui, sans-serif;
	font-weight: 500;
	flex-basis: 100%;
	margin: 0;
}
.main__description {
	flex-basis: 18em;
	flex-grow: 1;
	padding-bottom: 2em;
	max-width: 52em;
}
.main__contributors {
	padding-bottom: 3em;
}
.main__contributors_list {
	list-style: none;
	padding: 0;
}


.constructor {
	display: flex;
	flex-wrap: wrap;
	min-height: 100vh;
}
.constructor__aside {
	padding: 1em 1em 1.5em;
	flex-basis: 18em;
	flex-grow: 1;
	display: inline-flex;
	flex-direction: column;
}
.constructor__title {
	margin: 0 0 .5em;
	font-size: 150%;
	font-family: 'TT Common', system-ui, sans-serif;
	font-weight: 500;
	line-height: 1.2;
}
.constructor__blocks {
	padding: 0;
	list-style: none;
	margin: 0;
}

.constructor__add {
	display: flex;
	flex-wrap: wrap;
	align-items: baseline;
	column-gap: .5em;
	margin-top: 1.5em;
}
.constructor__add_btn {
	background: none;
	padding: 0;
	margin: 0;
	border: 0;
	border-radius: 0;
	color: LinkText;
	cursor: pointer;
}

.constructor__preview {
	flex-basis: 600px;
	background-color: #F2F2F2;
	flex-grow: 1;
	border: 0;
	margin: 1em;
	border-radius: .5em;
	padding: 1em;
	min-width: 234px;
}
.block {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: .25em;
	border-radius: .25em;
	border: 1px solid ButtonFace;
}
.block__label {
	padding: .5em;
	margin: .25em;
	flex-grow: 1;
	border-radius: .2em;
}
.block__control {
	padding: .25em;
	margin: 0;
	border: 0;
	font-size: 2em;
	background: none;
	cursor: pointer;
	color: inherit;
	line-height: 1;
}
.block__control svg {
	width: 2em;
	height: auto;
	fill: currentColor
}
.block__settings {
	flex-grow: 1;
	padding: 0 .75em .75em;
}
.block__toggle {
	align-self: start;
}
.block__toggle::before {
	content: '🔼';
}
.block__toggle[aria-expanded="false"]::before {
	content: '🔽';
}
.block__toggle[aria-expanded="false"] + .block__settings {
	display: none;
}

.block__setting {
	display: inline-block;
	flex-wrap: wrap;
	align-items: baseline;
	margin-bottom: .5em;
	margin-right: 1.5em;
}
.block__setting--fluid {
	width: 100%;
}

input, select, textarea, option, button {
	font: inherit;
	max-width: 100%;
}

.block__input {
	border-radius: 0;
	border: 0;
	border-bottom: 1px solid;
	padding: .25em .5em;
	margin: 0;
	color: inherit;
}
textarea.block__input {
	width: 100%;
	box-sizing: border-box;
	resize: vertical;
}
select.block__input {
	-webkit-appearance: none;
	border: 1px solid;
	padding-right: 1.25em;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 150 190' xml:space='preserve'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='m37.31 116.794 11.255-11.762 19.753 22.402-1.949-64.513h17.786l-.777 63.962 17.604-21.777 11.71 11.228-36.32 34.941-39.062-34.481z'/%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-position: 100% 40%;
	background-size: 1.25em;
	background-color: Canvas;
}
.block__input[type="file"] {
	width: 240px;
}
.block__input[type="color"] {
	border: 1px solid;
	padding: 0;
	vertical-align: bottom;
}
.block__input[type="color"]::-webkit-color-swatch-wrapper {
	padding: 0;
}
.block__input--fluid {
	width: 100%;
	box-sizing: border-box;
	margin-bottom: .5em;
}

.constructor__controls {
	margin-top: auto;
}
.constructor__control {
	display: inline-block;
	margin: 1.5em 1.5em 0 0;
	text-decoration: none;
	color: LinkText;
	cursor: pointer;
	padding: 0;
	background: none;
	border: 0;
	border-radius: 0;
}
.constructor__control:focus-within {
	outline: auto;
}
.constructor__logo {
	display: inline-block;
	margin-top: 1.5em;
}
